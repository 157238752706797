
$(document).on('turbolinks:load', function() {

  $('.css-tab-button').click(function() {
  	var tabid = $(this).attr('id');
    var selname = tabid.replace("-button", "")
  	select_tab(selname);
    return false;
  });

});

select_tab = function(selname) {
	$('.css_tab_block').each(function(i, obj) {
  	var blockname = $(obj).attr('id').replace("-tab", "");
    var buttonid = '#' + blockname + '-button'
    var blockid = '#' + blockname + '-tab';
  	if (blockname == selname) {
  		$(blockid).show();
      $(buttonid).addClass('hs-tab-button-sel');
  	} else {
  		$(blockid).hide();
      $(buttonid).removeClass('hs-tab-button-sel');
  	}
  });
};

// Modals
close_modal = function(event, modid) {
  $(modid).hide();
  event.preventDefault();
}

// show a modal that does not need to be populated with data
show_modal = function(event, modid) {
  $(modid).show();
  event.preventDefault();
}

show_assign_org_form = function(event) {
  $('#org-assign-form').show();
  event.preventDefault();
}
