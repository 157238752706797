// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// console.log('Hello World from Webpacker - Herdsafe')

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

//require("channels")
var Trix = require("trix")
require("@rails/actiontext")

require('jquery')
window.jQuery = $;
window.$ = $;


// Adding jquery - UI
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../user_interface/interface.js';
import '../user_interface/carousel.js';
import '../user_interface/jquery_ui_scripts.js';
import '../user_interface/page_functions.js';
import '../user_interface/survey_runner.js';

// import '../user_interface/trix_extensions.js';
