
// This is called when a new object is loaded.  Put anything in here that uses jquery-ui


$(document).on('turbolinks:load', function() {
	setup_ui();
});

setup_ui = function() {
    $( ".datepicker" ).datepicker(
    		{ dateFormat: "D, d M yy" }
    );

    $("#sortable").sortable(	{
  		update: function( event, ui ) { sort_objects(ui); }
    });

		$("#section-sortable").sortable( {
			update: function( event, ui ) { sort_sections(ui); }
		});

    configure_drag_drop();

		$("#file-upload-button").change(function(e) {
	  var input = this;
	  if (input.files && input.files[0]) {
	      var reader = new FileReader();
	        reader.onload = function (e) {
	            $('#upload-image').attr('src', e.target.result);
	        };
	        reader.readAsDataURL(input.files[0]);
	    }
		});

		// functios table, show which variables are linked.
		$(".variable-link").hover(
			function() {
				varid = $(this).data("varid");
				$(".variable-link").each(function() {
					d = $(this).data("varid");
					if (d == varid) {
						$(this).css( "background-color", "#90ff90" );
					}
				});
			}, function() {
				$(".variable-link").each(function() {
					d = $(this).data("varid");
					$(this).css( "background-color", "" );
				});
			}
		)

};

// This sorts divs
sort_objects = function(element) {
	var url;
	if (element.item.is("div")) {
		url = $(element.item).data('target');
	} else {
		var zzz = $('#sortable')
		url = $(zzz).data('target');
	}
	var sortedIDs = $( "#sortable" ).sortable( "serialize" );
	$.ajax({
		url: url,
		type: 'PATCH',
		data: sortedIDs,
		dataType: "script",
		success: function(data) {
		}
	});
};


sort_sections = function(element) {
	var url = $("#section-sortable").data('target');
	var sortedIDs = $( "#section-sortable" ).sortable( "serialize" );
	$.ajax({
		url: url,
		type: 'PATCH',
		data: sortedIDs,
		dataType: "script",
		success: function(data) {
		}
	});
};


// user interface draggable and droppable
$(function() {
    configure_drag_drop();
});

configure_drag_drop = function() {
	$(".draggable").draggable(
		{
		  helper: "clone"
		}
    );
    $(".droppable").droppable(
		{
			accept: ".draggable",
			drop: function( event, ui ) { object_dropped(event, ui); }
		}
    );
};

// draggable and droppable called on page ready in page_functions.js
object_dropped = function(event, object) {

	var dropped = $(event.target);
	var dragged = object.draggable;

	var pars = dropped.attr('id').split("-");
	var progid = pars[1];
	var action = pars[2];
	var obj = pars[3];
	var mthd;

	if (obj == 'site' ) { mthd = (action == 'inc') ? "/add_site/" : "/rem_site/" }
	else if (obj == 'user' ) { mthd = (action == 'inc') ? "/add_user/" : "/rem_user/" }
	else if (obj == 'org' ) { mthd = (action == 'inc') ? "/add_org/" : "/rem_org/" }
	else if (obj == 'survey' ) { mthd = (action == 'inc') ? "/add_survey/" : "/rem_survey/" }

	pars = dragged.attr('id').split('-');
	var objectid = pars[2];

	url = '/programs/' + progid + mthd + objectid

	$.ajax({
		url: url,
		type: 'POST',
		dataType: "script",
		success: function(data) {
		},
		error: function(x,e){
		}
	});
};
