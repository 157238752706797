
configure_question = function() {
  var qtype = $('#answer-form-type').data('qtype');
  if (qtype == 1) {
    $('.hs-answer-check').change(function() {
      var box = $(this);
      var state = $(box).prop('checked');
      $('.hs-answer-check').each(function(i, obj) {
          $(obj).prop('checked', false);
      });
      $(box).prop('checked', state);
    });
  } else if (qtype == 5 || qtype == 8) {
    $('.hs-answer-check').change(function(event) {
      var box = $(this);
      var state = $(box).prop('checked');
      var isexcl = $(box).data("exclude");
      if (isexcl) {
        if (state) {
          $('.hs-answer-check').each(function(i, obj) {
            if ($(obj).attr('id') != $(box).attr('id')) {
              $(obj).prop('checked', false);
            }
          });
        }
      } else {
        $('.hs-answer-check').each(function(i, obj) {
            if ($(obj).data("exclude")) {
              $(obj).prop('checked', false);
            }
        });
      }
    });
  }
}
